import { Typography } from "@mui/material";
import { FC } from "react";

const Privacy: FC = () => {
  document.title = "Synthesis | Privacy";
  return (
    <>
      <Typography>
        This Privacy Policy describes how thesynthesis.io (“we” or “us”) collects, uses, and
        discloses your personal information. By using our website, you consent to our collection,
        use, and disclosure of your personal information in accordance with this Privacy Policy.
      </Typography>
      <Typography variant="h6">The type of personal information we collect</Typography>
      <Typography>We currently collect and process the following information:</Typography>
      <ul>
        <Typography component="li">
          Contact Information: We may collect your name, email address.
        </Typography>
        <Typography component="li">
          Usage Information: We may collect information about how you use our website, including the
          pages you visit and the actions you take.
        </Typography>
        <Typography component="li">
          Other Information: We may collect other personal information about you that you choose to
          provide to us.
        </Typography>
      </ul>
      <Typography variant="h6">How we get the personal information and why we have it</Typography>
      <Typography>
        Most of the personal information we process is provided to us directly by you for one of the
        following reasons:
      </Typography>
      <ul>
        <Typography component="li">
          When you register on our website, home.thesynthesis.io
        </Typography>
        <Typography component="li">When you use or interact with our website</Typography>
        <Typography component="li">Publicly available sources</Typography>
      </ul>

      <Typography variant="h6">
        We use the information that you have given us in order to
      </Typography>
      <ul>
        <Typography component="li">To provide our services to you;</Typography>
        <Typography component="li">To improve our website and services;</Typography>
        <Typography component="li">
          To communicate with you about our website and services;
        </Typography>
        <Typography component="li">To comply with applicable laws and regulations.</Typography>
      </ul>
      <Typography variant="h6">How we store your personal information</Typography>
      <Typography>
        We store your information ourselves in our secure servers in the UK, the EU or the US.
      </Typography>
      <Typography>
        How long we keep your information will depend on the purpose for which we use it. We will
        only retain your information for as long as is necessary.
      </Typography>
      <Typography variant="h6">Cookies and Tracking Technologies</Typography>
      <Typography>
        We may use cookies and other tracking technologies on our website to collect information
        about your use of our website and to improve your experience.
      </Typography>

      <Typography variant="h6">Your data protection rights</Typography>
      <Typography>Under data protection law, you have rights including:</Typography>
      <ul>
        <Typography component="li">
          Your right of access - You have the right to ask us for copies of your personal
          information.
        </Typography>
        <Typography component="li">
          Your right to rectification - You have the right to ask us to rectify personal information
          you think is inaccurate. You also have the right to ask us to complete information you
          think is incomplete.
        </Typography>
        <Typography component="li">
          Your right to erasure - You have the right to ask us to erase your personal information in
          certain circumstances.
        </Typography>
        <Typography component="li">
          Your right to restriction of processing - You have the right to ask us to restrict the
          processing of your personal information in certain circumstances.
        </Typography>
        <Typography component="li">
          Your right to object to processing - You have the the right to object to the processing of
          your personal information in certain circumstances.
        </Typography>
        <Typography component="li">
          Your right to data portability - You have the right to ask that we transfer the personal
          information you gave us to another organisation, or to you, in certain circumstances.
        </Typography>
      </ul>
      <Typography>
        Please contact us at info info [at] thesynthesis.io if you wish to make a request.
      </Typography>
    </>
  );
};

export { Privacy };
